import React, {useEffect, useLayoutEffect, useRef, useState} from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import {injectIntl} from "react-intl"
import Helmet from "react-helmet"
import {StringParam, withQueryParams} from "use-query-params"
import styled from "styled-components"
import SEO from "../../components/common/layout/seo"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllFeatures from "../../components/commonV3/features/AllFeatures";
import ProsegurLogo from "../../components/commonV3/ProsegurLogo";
import LayoutWatches from "../../components/common/layout/LayoutWatches";
import FAQSCard from "../../components/commonV3/faqs/FAQSSection";
import IDownload from "../../images/svgs/download.svg"
import Loadable from "@loadable/component";
import ShippingBuyPage from "../../components/homeV3/ShippingBuyPage";
import IShipping from "../../images/svgs/EnviosDEF.svg";
import ITrial from "../../images/svgs/Trial.svg";
import ProsegurLogoD from "../../images/svgs/LogoMovistarProsegurD.svg"
import PlansBuyPage from "../../components/homeV3/PlansBuyPage";
import IUnion from "../../images/svgs/union.svg"
import CatalogButton from "../../components/commonV3/CatalogButton"
import { navigate } from "@reach/router"
import { checkLanguage, paths } from "../../utils"



const BuyFixedBottom = Loadable(() => import("../../components/homeV3/BuyFixedBottom"))

const Compra = ({ location, intl, query }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_buy_from" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_buy_from" })
  const { purchase_method, userId, promotion_code, fpr } = query

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [nav3, setNav3] = useState();
  const [nav4, setNav4] = useState();
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = function () {
    setShowMore(!showMore);
  }

  const downloadManual = function () {
    //window.dataLayer.push({ "event": "moreinfoClick" });
    /*const url = intl.formatMessage({ id: "buy_more_info_url" });
    const evtObject = JSON.stringify({
      "name": "openBrowser",
      "url": url,
    })*/

    navigate("/"+checkLanguage(location, intl)+
      paths[intl.locale].folleto+location.search);

   /* if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsMessageHandler &&
      window.webkit.messageHandlers.jsMessageHandler.postMessage) {
      window.webkit.messageHandlers.jsMessageHandler.postMessage(evtObject)
    } else if (window.JsMessageHandler && window.JsMessageHandler.publish) {
      window.JsMessageHandler.publish(evtObject)
    } else {
      window.open(url, "_blank")
    }*/
  }

  const desktopFeatures = AllFeatures({intl,layoutTypeCompra:true}); //,  className: "featureDesktopClass"});
  const [showMoreF, setShowMoreF] = useState(false);
  let monthlyPrice = parseFloat(process.env.GATSBY_SUPERMONTHLY_PRICE);
  let annualPrice = parseFloat(process.env.GATSBY_ANNUAL_PRICE);
  const  GA_ecommerce =   {
    currency: 'EUR',
    items: [{
      item_id: 'ANNUAL',
      item_name: 'Durcal Watch ANNUAL',
      price: annualPrice,
      currency: 'EUR',
      quantity: 1
    },{
      item_id: 'SUPERMONTHLY',
      item_name: 'Durcal Watch MONTHLY',
      price: monthlyPrice,
      currency: 'EUR',
      quantity: 1
    }],
    value: monthlyPrice+annualPrice
  }

  const toggleShowMoreF = function () {
    setShowMoreF(!showMoreF);
  }
  let cardPriceRef = useRef(null);

  useEffect(() => {
    window.dataLayer.push({ "event": "view_item",ecommerce: GA_ecommerce});
  },[]);

  const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "slider" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                originalName
                src
                srcSet
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      },
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "union" }
      ) {
        childImageSharp {
          fluid(maxWidth: 18, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function(i) {
      return (
        <a>
          <StyledImage fluid={images.AllPostImages.edges[i].node
            .childImageSharp.fluid} />
        </a>
      );
    }
  }
  const sliderDotsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
  }
  const sliderFeaturesSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    accessibility: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
  }

  const sliderFeaturesDotsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    initialSlide: 0,
    dotsClass: "slick-dots slick-thumb",
    customPaging: function (i) {
      return (
        <div className="dot"></div>
      );
    },
  }


  return (
    <>
      <SEO title={SEOTitle} description={SEODescription} />
      <Helmet>
        <link rel='canonical' href='https://www.durcal.com/es/reloj/compra'/>
        <script type="text/javascript">{`
          (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
          fpr("init", {cid:"wdxfm0r8"}); 
          fpr("click");
    `}</script>
        <script
          src="https://cdn.firstpromoter.com/fpr.js"
          type="text/javascript"
          async
        ></script>
      </Helmet>
      <LayoutWatches {...{ location }} layoutType="buy">
        <TitleSection>
          <h1><Title>{intl.formatMessage({ id: "buy_page_title" })}</Title></h1>
          <div className="LongDescription">
            {intl.formatMessage({id: "buyPageDescription"})}</div>
        </TitleSection>
        <GeneralDiv>
          <SliderDiv>
            <SliderWrapper>
              <Slider {...sliderSettings} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                {images.AllPostImages.edges.map(image => (
                  <ImageWrapper key={image.key}>
                    <StyledImage className="sliderrounded" fluid={image.node.childImageSharp.fluid} />
                  </ImageWrapper>
                ))}
              </Slider>
            </SliderWrapper>
            <SliderWrapperDots>
              <Slider {...sliderDotsSettings} asNavFor={nav1} ref={(slider2) => setNav2(slider2)}>
                <div></div><div></div><div></div><div></div>
              </Slider>
            </SliderWrapperDots>
          </SliderDiv>
          <DescriptionDiv>
            <TitleSectionD>
              <h1><Title>{intl.formatMessage({ id: "buy_page_title" })}</Title></h1>
              <div className="LongDescription">{intl.formatMessage({id: "buyPageDescription"})}</div>
            </TitleSectionD>

            <div className="ShipTrial" >
              <ShippingBuyPage {...{title:intl.formatMessage({id: "prosegurConnected247"}), description: intl.formatMessage({id: "prosegurConnected247alerts"}),icon: <ProsegurLogo/>,color:"#ECEBFD",className:"prosegurlogo"}}/>
              <ShippingBuyPage {...{title:intl.formatMessage({id: "prosegurConnected247"}), description: intl.formatMessage({id: "prosegurConnected247alerts"}),icon: <ProsegurLogoD/>,color:"#ECEBFD",className:"prosegurlogoD" }}/>
              <ShippingBuyPage {...{title:intl.formatMessage({id: "homeV3ShippingTitle"}), description: intl.formatMessage({id: "homeV3ShippingDescription"}),icon: <IShipping/>,className:"shipping"}}/>
              <ShippingBuyPage {...{title:intl.formatMessage({id: "homeV3TrialTitle"}), description: intl.formatMessage({id: "homeV3TrialDescription"}),icon: <ITrial/>,className:"shipping"}}/>
            </div>
            <div/>
            <SelectPlan>{intl.formatMessage({ id: "selectPlan" })}</SelectPlan>
            <div ref={cardPriceRef}>
              <PlansBuyPage intl={intl} location={location} userId={userId} promotion_code={promotion_code||fpr} purchase_method={purchase_method} icon={images.img1.childImageSharp.fluid} ecommerce={GA_ecommerce}/>
            </div>
            <BuyButtonWrapperD>
            </BuyButtonWrapperD>
            <CatalogButtonWrapper>
              <CatalogButton from={"buy"}/>
            </CatalogButtonWrapper>

            <FeaturesSection>
              <FeatureIconsWrapper>
                {desktopFeatures}
              </FeatureIconsWrapper>
            </FeaturesSection>
            <FeaturesSectionD>
              <DescriptionFeaturesWrapper>
                <h1>{intl.formatMessage({ id: "homeV3FeaturesSubtitle" })}</h1>
              </DescriptionFeaturesWrapper>
              <FeatureIconsWrapper>
                {desktopFeatures.slice(0,3)}
                <div className={"hiddenFeatures"+(showMoreF? "Expand": "")}>
                  {desktopFeatures.slice(3)}
                </div>
              </FeatureIconsWrapper>
              <div className="seemore"><a onClick={toggleShowMoreF}>{intl.formatMessage({ id: "Read" })} {(showMoreF? intl.formatMessage({ id: "Less" }): intl.formatMessage({ id: "More" }))}</a></div>
            </FeaturesSectionD>
            <IncludesWrapper>
              <h5>{intl.formatMessage({ id: "includesTitle" })}</h5>
              <div className="DescriptionWrapper">
                <IncludesDetailWrapper>
                  <Includes><IUnion/>{intl.formatMessage({ id: "includes1" })}</Includes>
                  <Includes><IUnion/>{intl.formatMessage({ id: "includes2" })}</Includes>
                  <Includes><IUnion/>{intl.formatMessage({ id: "includes3" })}</Includes>
                  <Includes><IUnion/>{intl.formatMessage({ id: "includes4" })}</Includes>
                  <Includes><IUnion/>{intl.formatMessage({ id: "includes5" })}</Includes>
                  <Includes><IUnion/>{intl.formatMessage({ id: "includes6" })}</Includes>
                </IncludesDetailWrapper>
              </div>
            </IncludesWrapper>
            <DownloadWrapper>
              <div className="downloaddesc"><a onClick={downloadManual}>{intl.formatMessage({ id: "DownloadManual" })}</a></div>
              <div><a onClick={downloadManual}><IDownload/></a></div>
            </DownloadWrapper>
          </DescriptionDiv>
        </GeneralDiv>
        <FAQSCard/>
        <BuyFixedBottom  {...{ location, layoutType: "buy" }}/>
      </LayoutWatches>
    </>
  )
}

const GeneralDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;

    @media (min-width: ${props => props.theme.screen.xl}) {
        flex-direction: row;
    }
`

const SliderDiv = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.screen.xl}) {
        width: 40%;
        margin-top: 40px;
        margin-left: 64px;
    }
`

const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.screen.xl}) {
        width: 50%;
    }

    .ShipTrial {
        margin: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (min-width: ${props => props.theme.screen.xl}) {
            flex-direction: row;
            margin: 0 64px 32px 0;
        }
        .prosegurlogowrapper {
            width: unset;
            @media (max-width: ${props => props.theme.screen.sm}) {
                width: 120px;
            }
            @media (min-width: ${props => props.theme.screen.md}) {
                width: 150px;
            }
            @media (max-width: ${props => props.theme.screen.lg}) {
                width: 150px;
            }
        }
        .prosegurlogo {
            display: flex;
            flex-direction: row-reverse;
            @media (min-width: ${props => props.theme.screen.xl}) {
                display: none;
            }
        }
        .prosegurlogoD {
            display: none;
            @media (min-width: ${props => props.theme.screen.xl}) {
                display: flex;
                flex-direction: column;
            }
        }
        .shipping {
            flex-direction: row-reverse;
            @media (min-width: ${props => props.theme.screen.xl}) {
                flex-direction: column;
            }
        }
    }
`

const TitleSection = styled.div`
    .LongDescription {
        margin: 16px;
        line-height: 20px;
        ${({theme}) => theme.font_size.small};
        @media (min-width: ${props => props.theme.screen.xl}) {
            margin: 0 64px 32px 0;
        }
    }
    @media (min-width: ${props => props.theme.screen.xl}) {
        display: none;
    }
`
const TitleSectionD = styled.div`
    display: none;
    @media (min-width: ${props => props.theme.screen.xl}) {
        display: block;
        order: 0;
        margin: 0 64px 32px 0;
        line-height: 20px;
    }
`

const FeaturesSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (min-width: ${props => props.theme.screen.xl}) {
        display: none;
    }
`

const FeaturesSectionD = styled.div`
    display: none;

    @media (min-width: ${props => props.theme.screen.xl}) {
        display: flex;
        flex-direction: column;
        order: 4;
        gap: 32px;

        margin: 0 64px 32px 0;

        .seemore {
            color: ${({ theme }) => theme.color.purple};
            text-decoration: underline;
            padding-top: 8px;
            text-align: right;
            ${({ theme }) => theme.font_size.regular};
            font-family: ${({ theme }) => theme.font.bold};
        }
    }
`


const Title = styled.div`
  margin: 16px;
  margin-bottom: 8px;
  ${({ theme }) => theme.font_size.xlarge};
  font-family: ${({ theme }) => theme.font.bold};

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 40px 64px 16px 0;
    ${({ theme }) => theme.font_size.xxlarge};
  }
`
const Price = styled.div`
  margin: 16px;
  margin-top: 0;
  ${({ theme }) => theme.font_size.xlarge};
  font-family: ${({ theme }) => theme.font.bold};
  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 0 64px 32px 0;
    ${({ theme }) => theme.font_size.xxlarge};
  }
`

const PriceFrom = styled.div`
  margin: 16px 16px 0 16px;
  ${({ theme }) => theme.font_size.regular};
  font-family: ${({ theme }) => theme.font.medium};
  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 0 64px 10px 0;
    ${({ theme }) => theme.font_size.regular};
  }
`
const SelectPlan = styled.div`
  margin: 0 18px 18px 16px;
  ${({ theme }) => theme.font_size.regular};
  font-family: ${({ theme }) => theme.font.bold};
  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 0 18px 18px 0;
  }
`

const PriceAnnual = styled.span`
  ${({ theme }) => theme.font_size.small};
  font-family: ${({ theme }) => theme.font.medium};
  @media (min-width: ${props => props.theme.screen.xl}) {
    ${({ theme }) => theme.font_size.medium};
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  display: block;
`
const StyledIcon = styled(Img)`
  position: absolute;
  width: 18px;
  height: 18px;
`
const SliderWrapper = styled.div`
  width: 100%;
  .slick-dots {
    position: relative;
  }
  .slick-dots.slick-thumb li {
    width: 40px;
    height: 100%;
  }
  .slick-dots.slick-thumb li.slick-active {
    outline: 2px solid ${({ theme }) => theme.color.purple};
    contain: content;
  }
  .sliderrounded {border-radius: 16px};
`
const SliderWrapperDots = styled.div`
  padding-bottom: 32px;
  .slick-dots {
    position: relative;
  }
  .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .slick-dots.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 16px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: ${({ theme }) => theme.color.neutral};
  }

  .slick-dots.slick-thumb li.slick-active {
    background-color: ${({ theme }) => theme.color.purple};;
    width: 35px;
  }
`

const SliderWrapperFeatures = styled.div`
  //height: 132px;

  .slick-list {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
  }

`
const ImageWrapper = styled.div`
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  border-radius: 16px;
`

const ProsegurConnectedSectionWrapper = styled.div`
  @media (min-width: ${props => props.theme.screen.xl}) {
    order: 1;
    justify-content: space-between;
    margin: 0 64px 32px 0;
  }


  margin: 16px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 24px 16px 16px;
  gap: 24px;

  /* Primary (Purple)/10 */
  background: #ECEBFD;

  /* bakcround blur */
  

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;

  .DescriptionWrapper {
    h3 {
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.bold};

      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.regular};
      }
    }

  }
`

const DownloadWrapper = styled.div`
    @media (min-width: ${props => props.theme.screen.xl}) {
        order: 5;
        margin: 0 64px 56px 0;
    }

    margin: 16px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 24px 16px 16px;
    gap: 24px;

    /* Primary (Purple)/10 */
    background: #FFFFFF;

    /* bakcround blur */
    

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    .downloaddesc {
        ${({ theme }) => theme.font_size.small};
        font-family: ${({ theme }) => theme.font.bold};
        color: ${({ theme }) => theme.color.purple};
        text-decoration: underline;

        @media (min-width: ${props => props.theme.screen.xl}) {
            ${({ theme }) => theme.font_size.regular};
        }
    }

    svg {
        width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.color.purple};
    }

`



const DescriptionFeaturesWrapper = styled.div`
  margin: 16px;

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 32px 0 0 0;
  }

  h1,h2  {
    ${({theme}) => theme.font_size.large};
    font-family: ${({theme}) => theme.font.bold};
    margin-bottom: 0;

    @media (min-width: ${props => props.theme.screen.xl}) {
      ${({ theme }) => theme.font_size.xlarge};
    }
  }
`
const IncludesWrapper = styled.div`
    @media (min-width: ${props => props.theme.screen.xl}) {
        order: 3;
        margin: 0 64px 0 0;
        padding: 32px;

    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    padding: 16px;
    gap: 32px;

    background: #FFFFFF;
    border-radius: 20px;

    flex: none;
    flex-grow: 0;
    .DescriptionWrapper {
        width: 100%;
        ${({ theme }) => theme.font_size.small};
        font-family: ${({ theme }) => theme.font.thin};

    }

    .seemore {
        color: ${({ theme }) => theme.color.purple};
        text-decoration: underline;
        padding-top: 34px;
        text-align: right;
        ${({ theme }) => theme.font_size.regular};
        font-family: ${({ theme }) => theme.font.bold};
    }

    h5 {
        margin: 0;
        ${({ theme }) => theme.font_size.large};
        font-family: ${({ theme }) => theme.font.bold};

        @media (min-width: ${props => props.theme.screen.xl}) {
            ${({ theme }) => theme.font_size.xlarge};
        }
    }
`
const IncludesDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 32px;
    width: 100%;
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    .hiddenIcludes {
        display: none;
    }
    
    svg {min-height: 18px;min-width:18px;}

    .hiddenIcludesExpand {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 32px;
        width: 100%;
        flex: none;
        align-self: stretch;
        flex-grow: 0;
    }
`
const Includes = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;

  width: 100%;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
  ${({ theme }) => theme.font_size.small};
  font-family: ${({ theme }) => theme.font.thin};

  @media (min-width: ${props => props.theme.screen.xl}) {
    ${({ theme }) => theme.font_size.regular};
  }
`


const BuyButtonWrapperD = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.screen.xl}) {
    order: 2;
    //max-width: fit-content;
    display: flex;
    margin: 0 64px 32px 0;
    gap: 75px;
    button {
      padding: 30px 30px;
    }
    //display: block;
    padding: 0;
  }
`

const FeatureIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  justify-content: stretch;

  .featureDesktopClass {
    width: 31.532%;
    flex-grow: 0;
  }


  .hiddenFeatures {
    display: none;
  }

  .hiddenFeaturesExpand {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    width: 100%;
    gap: 16px;
    justify-content: stretch;
  }

`


const CatalogButtonWrapper = styled.div`
  margin: 0 64px 32px 0;
  @media (max-width: ${props => props.theme.screen.xl}) {
    margin: 16px;
  }

`

export default withQueryParams(
  {
    purchase_method: StringParam,
    userId: StringParam,
    promotion_code: StringParam,
    fpr: StringParam
  },
  injectIntl(Compra),
)
